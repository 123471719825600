import React, { useEffect, useContext } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Switch,
} from "@material-ui/core";
import Axios from "axios";
import { DatabaseContext } from "./../../contexts/DatabaseContext";
import { useNavigate } from "react-router-dom";

import lanyardsImage from "../../images/Accessories/SRLanyards.jpg";
import beltClipImage from "../../images/Accessories/BeltClip1.jpg";
import phoneHolderImage from "../../images/Accessories/Phone_Holder.gif";
import strimCardImage from "../../images/Accessories/Strim.gif";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { red } from "@material-ui/core/colors";
import MWACard from "../../components/Card";
import Popup from "../../components/Popup";
import { useSnackbar } from "notistack";
import Select, { components } from "react-select";
import NoveltyBeltClipsProductImage from "../../images/Accessories/SRNoveltyBeltClips.jpg";
import SRNoveltyLanyards1 from "../../images/Accessories/SRNoveltyLanyards1.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    // transition: theme.transitions.create("transform", {
    //   duration: theme.transitions.duration.shortest,
    // }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function Accessories() {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();
  const [showPopup, setShowPopUp] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [itemName, setItemName] = useState();
  const [itemCost, setItemCost] = useState();
  const [itemCode, setItemCode] = useState();
  const [itemMessage, setItemMessage] = useState();
  const [itemOptions, setItemOptions] = useState([]);
  const [cart, setCart] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [SelectCustom, setSelectCustom] = useState(false);
  const [CusLData, setCusLData] = useState([]);
  const [SelectCusBelt, setSelectCustBelt] = useState(false);
  const [SelectCustPhone, setSelectCustPhone] = useState(false);
  const [BeltClipOptions, setBeltClipOptions] = useState([]);
  const [LanyardOptions, setLanyardOptions] = useState([]);
  const [standardLanyards, setStandardLanyards] = useState([]);
  const [cardHolders, setCardHolders] = useState([]);
  const [standardBeltClips, setStandardBeltClips] = useState([]);
  const [strimCards, setStrimCards] = useState([]);
  const { IDCaptureAPI, imageURL } = useContext(DatabaseContext);
  const navigate = useNavigate();

  const { SingleValue, Option } = components;

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <img
        src={props.data.image}
        style={{
          height: "30%",
          width: "30%",
          //borderRadius: '50%',
          marginRight: "10px",
        }}
      />
      {props.data.itemName}
    </SingleValue>
  );

  const IconOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.image}
        style={{
          height: "30%",
          width: "15%",
          backgroundColor: "black",
          //borderRadius: '50%',
          marginRight: "20%",
        }}
      />
      {props.data.itemName}
    </Option>
  );

  // Step 3
  const customStyles = {
    option: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "Black",
      marginLeft: "10%",
      width: "80%",
      fontWeight: "bold",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "black",
      marginLeft: "10%",
      width: "80%",
      fontWeight: "bold",
    }),
  };

  const settingCustom = async () => {
    setSelectCustom(true);
  };

  const settingCusBeltClip = async () => {
    setSelectCustBelt(true);
  };

  const settingCustomPhone = async () => {
    setSelectCustPhone(true);
  };

  const back = async () => {
    navigate("/confirmdetails", { replace: true });
  };

  const next = async () => {
    cart.forEach((cartItem) => {
      if (cartItem.itemName != undefined) {
        switch (cartItem.itemName) {
          case "Lanyards": {
            if (cartItem.itemID == undefined) {
              standardLanyards.forEach((lanyard) => {
                if (
                  lanyard.itemName.toString().toLowerCase() ==
                  cartItem.itemOption.toString().toLowerCase()
                ) {
                  cartItem.itemID = lanyard.itemID;
                }
              });
            }
          }
          case "Card Holder": {
            if (cartItem.itemID == undefined) {
              cardHolders.forEach((cardHolder) => {
                if (
                  cardHolder.itemName.toString().toLowerCase() ==
                  cartItem.itemName.toString().toLowerCase()
                ) {
                  cartItem.itemID = cardHolder.itemID;
                }
              });
            }
          }
          case "Belt Clips": {
            if (cartItem.itemID == undefined) {
              standardBeltClips.forEach((beltclip) => {
                if (
                  beltclip.itemName.toString().toLowerCase() ==
                  cartItem.itemName.toString().toLowerCase()
                ) {
                  cartItem.itemID = beltclip.itemID;
                }
              });
            }
          }
          case "STRIM Card": {
            if (cartItem.itemID == undefined) {
              strimCards.forEach((strim) => {
                if (
                  strim.itemName.toString().toLowerCase() ==
                  cartItem.itemName.toString().toLowerCase()
                ) {
                  cartItem.itemID = strim.itemID;
                }
              });
            }
          }
        }
      }
    });

    localStorage.setItem("cartItems", JSON.stringify(cart));
    navigate("/payment", { replace: true });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function buyItem(itemName, itemCost, itemCode, options) {
    setShowPopUp(false);
    setItemOptions(options);
    if (options.length > 0) {
      setShowPopUp(true);
      setItemName(itemName);
      setItemCost(itemCost);
      setItemCode(itemCode);
      setItemMessage(
        `Please select the colour of the ${itemName} that you wish to purchase`
      );
    } else {
      console.log(itemName);
      if (itemName != undefined) {
        cart.push({
          itemID: itemCode,
          itemName: itemName,
          itemOption: "",
          itemCost: itemCost,
        });
        enqueueSnackbar(`${itemName} has been added to your order`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`You need to select an item to purhase`, {
          variant: "error",
        });
      }
    }
  }

  const getNovelityBeltClips = async () => {
    try {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/getAccessories/?accessoryType=6`
      );
      const dataArray = [];

      res.data.data[0].forEach((item) => {
        dataArray.push({
          itemName: item.AccessoryName,
          itemID: item.AccessoryCode,
          image: `${imageURL}/${item.AccessoryImagePath}`,
          itemCost: item.AccessoryCost,
        });
      });
      setBeltClipOptions([]);
      setBeltClipOptions(dataArray);
    } catch (err) {
      console.log(err);
    }
  };

  const getStandardLanyards = async () => {
    try {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/getAccessories/?accessoryType=4`
      );
      const dataArray = [];

      res.data.data[0].forEach((item) => {
        dataArray.push({
          itemName: item.AccessoryName,
          itemID: item.AccessoryCode,
          image: `${imageURL}/${item.AccessoryImagePath}`,
          itemCost: item.AccessoryCost,
        });
      });
      setStandardLanyards([]);
      setStandardLanyards(dataArray);
    } catch (err) {
      console.log(err);
    }
  };

  const getStrimCards = async () => {
    try {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/getAccessories/?accessoryType=2`
      );
      const dataArray = [];

      res.data.data[0].forEach((item) => {
        dataArray.push({
          itemName: item.AccessoryName,
          itemID: item.AccessoryCode,
          image: `${imageURL}/${item.AccessoryImagePath}`,
          itemCost: item.AccessoryCost,
        });
      });
      setStrimCards([]);
      setStrimCards(dataArray);
    } catch (err) {
      console.log(err);
    }
  };

  const getCardHolders = async () => {
    try {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/getAccessories/?accessoryType=3`
      );
      const dataArray = [];

      res.data.data[0].forEach((item) => {
        dataArray.push({
          itemName: item.AccessoryName,
          itemID: item.AccessoryCode,
          image: `${imageURL}/${item.AccessoryImagePath}`,
          itemCost: item.AccessoryCost,
        });
      });
      setCardHolders([]);
      setCardHolders(dataArray);
    } catch (err) {
      console.log(err);
    }
  };

  const getStandardBeltClips = async () => {
    try {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/getAccessories/?accessoryType=1`
      );
      const dataArray = [];

      res.data.data[0].forEach((item) => {
        dataArray.push({
          itemName: item.AccessoryName,
          itemID: item.AccessoryCode,
          image: `${imageURL}/${item.AccessoryImagePath}`,
          itemCost: item.AccessoryCost,
        });
      });
      setStandardBeltClips([]);
      setStandardBeltClips(dataArray);
    } catch (err) {
      console.log(err);
    }
  };

  const getNovelityLanyards = async () => {
    try {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/getAccessories/?accessoryType=5`
      );
      const dataArray = [];

      res.data.data[0].forEach((item) => {
        dataArray.push({
          itemName: item.AccessoryName,
          itemID: item.AccessoryCode,
          image: `${imageURL}/${item.AccessoryImagePath}`,
          itemCost: item.AccessoryCost,
        });
      });
      setLanyardOptions([]);
      setLanyardOptions(dataArray);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    //Set Belt Clips
    if (BeltClipOptions.length == 0) getNovelityBeltClips();
    //Set Novelity Lanyards
    if (LanyardOptions.length == 0) getNovelityLanyards();
    //Standard Lanyards
    if (standardLanyards.length == 0) getStandardLanyards();
    //Card Holders
    if (cardHolders.length == 0) getCardHolders();
    //Standard Belt Clips
    if (standardBeltClips.length == 0) getStandardBeltClips();
    //STRIM Cards
    if (strimCards.length == 0) getStrimCards();

    if (!cart) {
      setCart(JSON.parse(localStorage.getItem("cartItems")));
    }
    setTimeout(() => {
      localStorage.removeItem("siteName");
      localStorage.removeItem("siteCode");
      localStorage.removeItem("siteOrderTypeID");
      localStorage.removeItem("firstName");
      localStorage.removeItem("middleName");
      localStorage.removeItem("surname");
      localStorage.removeItem("DateOfBirth");
      localStorage.removeItem("phone");
      localStorage.removeItem("email");
      localStorage.removeItem("parent");
      localStorage.removeItem("ResStreet");
      localStorage.removeItem("ResSuburb");
      localStorage.removeItem("ResState");
      localStorage.removeItem("ResPostcode");
      localStorage.removeItem("street");
      localStorage.removeItem("suburb");
      localStorage.removeItem("state");
      localStorage.removeItem("postcode");
      localStorage.removeItem("cartItems");
      localStorage.removeItem("ExternalID");
      window.location.href = "/buyonline";
    }, 7200000);
  });

  const HandleCustomItem = (event) => {
    setCusLData(event);
  };

  const AddCard = () => {
    setSelectCustom(false);
    setSelectCustBelt(false);
    setSelectCustPhone(false);
    if (CusLData.itemName != undefined) {
      cart.push({
        itemName: CusLData.itemName,
        itemCost: CusLData.itemCost,
        itemID: CusLData.itemID,
      });
      enqueueSnackbar(`${CusLData.itemName} has been added to your order`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(`You need to select an item to purchase`, {
        variant: "error",
      });
    }
  };

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Optional Accessories
        </h>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={6}>
            <p
              style={
                window.innerWidth > 775
                  ? { fontSize: 20, marginleft: "40px" }
                  : {
                      marginLeft: "8%",
                      marginRight: "5%",
                      fontSize: "140%",
                    }
              }
            >
              Now is a good time to add some optional accessories for your new
              smartrider. Please make your selection from the Smartrider
              accessories below
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={3}></Grid>
        </Grid>
        <Popup
          trigger={showPopup}
          setTrigger={setShowPopUp}
          title={itemName}
          message={itemMessage}
          cost={itemCost}
          options={itemOptions}
          setSelectedItem={setSelectedItem}
          item={selectedItem}
          cartArray={cart}
        ></Popup>
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={12} md={1}></Grid>
          <Grid item xs={12} sm={12} md={10}>
            <Grid container spacing={2} direction="row" justify="center">
              <Grid
                item
                xs={12}
                //sm={12}
                md={2}
                style={
                  window.innerWidth > 775
                    ? { marginLeft: "" }
                    : { marginLeft: "10%" }
                }
              >
                <MWACard
                  title="Lanyards"
                  subtitle="Cost : $5 each"
                  image={lanyardsImage}
                  textBody="Available in a range of colours and all lanyards come with a clear plastic card holder"
                  expandedBody="Colours available Red,Blue,Green,Yellow,White and Black"
                  cardColor="#273d78"
                  options={[
                    "No Colour",
                    "Black",
                    "Royal Blue",
                    "Bright Green",
                    "Dark Green",
                    "Red",
                    "White",
                    "Yellow",
                  ]}
                  cost="5"
                  buyClick={buyItem}
                ></MWACard>
              </Grid>

              <Grid
                item
                xs={12}
                //sm={12}
                md={2}
                style={
                  window.innerWidth > 775
                    ? { marginLeft: "" }
                    : { marginLeft: "10%" }
                }
              >
                <MWACard
                  title="Novelty Lanyards"
                  subtitle="Cost : $10 each"
                  image={SRNoveltyLanyards1}
                  textBody="Available in a range of designs and all lanyards come with a clear plastic card holder"
                  expandedBody="Designs include Dinosaurs, Rainbow,  Smileys and many more"
                  cardColor="#273d78"
                  cost="5"
                  buyClick={settingCustom}
                ></MWACard>
              </Grid>

              <Grid
                item
                xs={12}
                //sm={12}
                md={2}
                style={
                  window.innerWidth > 775
                    ? { marginLeft: "" }
                    : { marginLeft: "10%" }
                }
              >
                <MWACard
                  title="Card Holder"
                  image={phoneHolderImage}
                  subtitle="Cost : $8 or $10 each"
                  textBody="Mobile phone holder has an adhesive back to stick to your phone or case"
                  expandedBody="It clips out to also be a stand to sit your mobile device up in a vertical-sloped position"
                  cardColor="#273d78"
                  cost="10"
                  buyClick={settingCustomPhone}
                  options={[]}
                ></MWACard>
              </Grid>
              <Grid
                item
                xs={12}
                //sm={12}
                md={2}
                style={
                  window.innerWidth > 775
                    ? { marginLeft: "" }
                    : { marginLeft: "10%" }
                }
              >
                <MWACard
                  title="Belt Clips"
                  image={beltClipImage}
                  subtitle="Cost : $6.50 each"
                  textBody="Belt clips have a retractable cord and come with a clear plastic card holder"
                  cardColor="#273d78"
                  cost="6.50"
                  buyClick={buyItem}
                  options={[]}
                ></MWACard>
              </Grid>

              <Grid
                item
                xs={12}
                //sm={12}
                md={2}
                style={
                  window.innerWidth > 775
                    ? { marginLeft: "" }
                    : { marginLeft: "10%" }
                }
              >
                <MWACard
                  title="Novelty Belt Clips"
                  image={NoveltyBeltClipsProductImage}
                  subtitle="Cost : $10 each"
                  textBody="Belt clips have fun caricatures on the belt clip and include a retractable cord and come with a clear plastic card holder"
                  expandedBody="Some of the caricatures include super heros, popular characters and many fun designs"
                  cardColor="#273d78"
                  cost="10"
                  buyClick={settingCusBeltClip}
                ></MWACard>
              </Grid>

              <Grid
                item
                xs={12}
                //sm={}
                md={2}
                style={
                  window.innerWidth > 775
                    ? { marginLeft: "" }
                    : { marginLeft: "10%" }
                }
              >
                <MWACard
                  title="STRIM Card"
                  image={strimCardImage}
                  subtitle="Cost : $5 each"
                  textBody="A STRIM card will help protect your Smartrider from interferrance by your phone"
                  expandedBody="If you want to place your SmartRider card against the back of your phone or
                  other metallic device, the card may not work without the use of a STRIM card. Metal will
                  intefere with the signal between the SmartRider card and the card reader on the bus/train
                  sation/parking and other devices. Placing a STRIM card, between your phone and the SmartRider
                  card, allows the signal to work properly. Our STRIM card is slimline (only 0.5mm) thin, and very flexible.
                  We have tested this card on Transperth's readers, and the STRIM card allows you to use your SmartRider card,
                  whilst it is still stored safely inside your phone's case.
                  *Warning: we are unable to guarantee this card will function as expected, with all cases and all phones."
                  cardColor="#273d78"
                  cost="5"
                  buyClick={buyItem}
                  options={[]}
                ></MWACard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={1}></Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={12} md={12}></Grid>
          <Grid item xs={4} sm={4} md={3}></Grid>

          <Grid item xs={8} sm={6} md={6}>
            <Button
              style={
                window.innerWidth > 775
                  ? {
                      backgroundColor: "#D3212D",
                      color: "#ffffff",
                      marginLeft: "20%",
                      fontSize: 20,
                      width: "25%",
                    }
                  : {
                      backgroundColor: "#D3212D",
                      color: "#ffffff",
                      fontSize: "110%",
                    }
              }
              onClick={back}
            >
              Back
            </Button>

            <Button
              style={
                window.innerWidth > 775
                  ? {
                      backgroundColor: "green",
                      color: "#ffffff",
                      marginLeft: "2%",
                      fontSize: 20,
                      width: "25%",
                    }
                  : {
                      backgroundColor: "green",
                      color: "#ffffff",
                      marginLeft: "2%",
                      fontSize: "110%",
                    }
              }
              onClick={next}
              disabled={showPopup}
            >
              Continue
            </Button>
          </Grid>
          <Dialog fullWidth open={SelectCustom}>
            <DialogContent
              style={
                window.innerWidth > 775
                  ? {
                      height: "700px",
                      backgroundColor: "#273d78",
                    }
                  : {
                      height: "500px",
                      backgroundColor: "#273d78",
                    }
              }
            >
              <Select
                maxMenuHeight={"75%"}
                menuIsOpen
                onChange={HandleCustomItem}
                styles={customStyles}
                components={{
                  SingleValue: IconSingleValue,
                  Option: IconOption,
                }}
                options={LanyardOptions}
              />
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#273d78" }}>
              <Button
                variant="Outlined"
                style={{ color: "white" }}
                onClick={() => {
                  setSelectCustom(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="Outlined"
                style={{ color: "white" }}
                onClick={AddCard}
              >
                $ Purchase
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog fullWidth open={SelectCusBelt}>
            <DialogContent
              style={{
                height: "700px",
                backgroundColor: "#273d78",
              }}
            >
              <Select
                maxMenuHeight={"75%"}
                menuIsOpen
                onChange={HandleCustomItem}
                styles={customStyles}
                components={{
                  SingleValue: IconSingleValue,
                  Option: IconOption,
                }}
                options={BeltClipOptions}
              />
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#273d78" }}>
              <Button
                variant="Outlined"
                style={{ color: "white" }}
                onClick={() => {
                  setSelectCustBelt(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="Outlined"
                style={{ color: "white" }}
                onClick={AddCard}
              >
                $ Purchase
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog fullWidth open={SelectCustPhone}>
            <DialogContent
              style={{
                height: "700px",
                backgroundColor: "#273d78",
              }}
            >
              <Select
                maxMenuHeight={"75%"}
                menuIsOpen
                onChange={HandleCustomItem}
                styles={customStyles}
                components={{
                  SingleValue: IconSingleValue,
                  Option: IconOption,
                }}
                options={cardHolders}
              />
            </DialogContent>
            <DialogActions style={{ backgroundColor: "#273d78" }}>
              <Button
                variant="Outlined"
                style={{ color: "white" }}
                onClick={() => {
                  setSelectCustBelt(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="Outlined"
                style={{ color: "white" }}
                onClick={AddCard}
              >
                $ Purchase
              </Button>
            </DialogActions>
          </Dialog>

          <Grid item xs={4} sm={4} md={3}></Grid>
        </Grid>
        <Grid style={{ marginTop: "2%" }}></Grid>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};
const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

export default Accessories;
