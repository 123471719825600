import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  TextField,
  Button,
  Checkbox,
  MenuItem,
  Select,
  Grid,
  Container,
  Input,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import Axios from "axios";
import { DatabaseContext } from "./../../contexts/DatabaseContext";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

function StudentContactInformation() {
  const { enqueueSnackbar } = useSnackbar();
  const [isWondeSchool, setIsWondeSchool] = useState(false);
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [showPostalAddress, setShowPostalAddress] = useState();
  const [siteOrderTypeID, setSiteOrderTypeID] = useState("");
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(null);
  const [isMobileDevice, setIsMobileDevice] = useState();

  var phoneRegEx =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setValues,
    setDate,
    handleSubmit,
    resetForm,
    onChange,
    handleClick,
  } = useFormik({
    initialValues: {
      StudentNumber: "",
      Phone: "",
      Email: "",
      ParentGuardianName: "",
      Street: "",
      Suburb: "",
      State: "WA",
      Postcode: "",
      PostalStreet: "",
      PostalSuburb: "",
      PostalState: "WA",
      PostalPostcode: "",
      showPostalAddress: "",
    },
    validationSchema: Yup.object({
      //ID: Yup.number().integer(),
      StudentNumber: Yup.string().nullable(),
      Phone: Yup.string()
        .nullable()
        .matches(phoneRegEx, "Please enter a valid phone number"),
      Email: Yup.string().email().required("Please Enter your Email"),
      ParentGuardianName: Yup.string().nullable(),
      Street: Yup.string().required("Please Enter your street"),
      Suburb: Yup.string().required("Please Enter your suburb"),
      State: Yup.string().required("Please choose your state"),
      Postcode: Yup.string().required().max(4, "Postcode can only be 4 digits"),
      showPostalAddress: Yup.boolean(),
      PostalStreet: Yup.string().when("showPostalAddress", {
        is: true,
        then: () => {
          Yup.string().required("Please Enter your street");
        },
      }),
      PostalSuburb: Yup.string().when("showPostalAddress", {
        is: true,
        then: () => {
          Yup.string().required("Please Enter your Suburb");
        },
      }),
      PostalState: Yup.string().when("showPostalAddress", {
        is: true,
        then: () => {
          Yup.string().required("Please choose your street ");
        },
      }),
      PostalPostcode: Yup.string().when("showPostalAddress", {
        is: true,
        then: () => {
          Yup.string()
            .required("Please enter your Postcode")
            .max(4, "Please enter your Postcode (4 digits only)");
        },
      }),
    }),
  });

  const handleAddressSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setAddress(results[0].formatted_address);
    var addr = results[0].formatted_address.split(",");
    var line2 = addr[1].trim().split(" ");

    values.Street = `${addr[0].toString()}`;

    switch (line2.length) {
      case 3: {
        values.Suburb = `${line2[0].toString()}`;
        values.State = `${line2[1].toString()}`;
        values.Postcode = `${line2[2].toString()}`;
        break;
      }
      case 4: {
        values.Suburb = `${line2[0].toString()} ${line2[1].toString()}`;
        values.State = `${line2[2].toString()}`;
        values.Postcode = `${line2[3].toString()}`;
        break;
      }
      case 5: {
        values.Suburb = `${line2[0].toString()} ${line2[1].toString()} ${line2[2].toString()}`;
        values.State = `${line2[3].toString()}`;
        values.Postcode = `${line2[4].toString()}`;
        break;
      }
    }
  };

  const showPostal = () => {
    if (!values.showPostalAddress) {
      values.showPostaAddress = true && {
        PostalSuburb: Yup.string().required(),
      };
    } else {
      values.showPostaAddress = false;
    }
  };

  const back = async () => {
    if (isWondeSchool == true) {
      navigate("/parentidcapture", { replace: true });
    } else {
      navigate("/studentlookup", { replace: true });
    }
    localStorage.removeItem("firstName");
    localStorage.removeItem("middleName");
    localStorage.removeItem("surname");
    localStorage.removeItem("DateOfBirth");
    localStorage.removeItem("phone");
    localStorage.removeItem("email");
    localStorage.removeItem("parent");
    localStorage.removeItem("ResStreet");
    localStorage.removeItem("ResSuburb");
    localStorage.removeItem("ResState");
    localStorage.removeItem("ResPostcode");
    localStorage.removeItem("street");
    localStorage.removeItem("suburb");
    localStorage.removeItem("state");
    localStorage.removeItem("postcode");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("ExternalID");
  };

  const getWondeSchool = async () => {
    var siteName = localStorage.getItem("siteName");

    if (siteName) {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/search/?filterInput=${siteName}`
      );
      var schoolDetails = res.data.data[0];

      setIsWondeSchool(schoolDetails.wondeAllowed > 0 ? true : false);
    }
  };

  const next = () => {
    values.Email = values.Email.trim();
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    handleSubmit();
    if (values.showPostalAddress !== true) {
      if (
        re.test(values.Email) &&
        values.Street &&
        values.Suburb &&
        values.State &&
        values.Postcode > 0
      ) {
        // save data
        localStorage.setItem("ExternalID", values.StudentNumber);
        localStorage.setItem("parent", values.ParentGuardianName);
        localStorage.setItem("email", values.Email);
        localStorage.setItem("phone", values.Phone);
        localStorage.setItem("ResStreet", values.Street);
        localStorage.setItem("ResSuburb", values.Suburb);
        localStorage.setItem("ResState", values.State);
        localStorage.setItem("ResPostcode", values.Postcode);
        navigate("/confirmdetails", { replace: true });
      } else {
        enqueueSnackbar(`Please ensure all required data is entered`, {
          variant: "error",
        });
      }
    }
    if (values.showPostalAddress === true) {
      if (
        re.test(values.Email) &&
        values.PostalPostcode &&
        values.PostalSuburb &&
        values.PostalState &&
        values.PostalPostcode > 0
      ) {
        // save data
        localStorage.setItem("ExternalID", values.StudentNumber);
        localStorage.setItem("parent", values.ParentGuardianName);
        localStorage.setItem("email", values.Email);
        localStorage.setItem("phone", values.Phone);
        localStorage.setItem("ResStreet", values.Street);
        localStorage.setItem("ResSuburb", values.Suburb);
        localStorage.setItem("ResState", values.State);
        localStorage.setItem("ResPostcode", values.Postcode);
        localStorage.setItem("street", values.PostalStreet);
        localStorage.setItem("suburb", values.PostalSuburb);
        localStorage.setItem("state", values.PostalState);
        localStorage.setItem("postcode", values.PostalPostcode);
        navigate("/confirmdetails", { replace: true });
      } else {
        enqueueSnackbar(`Please ensure all required data is entered`, {
          variant: "error",
        });
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    setIsMobileDevice(regexp.test(details));

    getWondeSchool();
    setSiteOrderTypeID(localStorage.getItem("siteOrderTypeID"));
    setTimeout(() => {
      localStorage.removeItem("siteName");
      localStorage.removeItem("siteCode");
      localStorage.removeItem("siteOrderTypeID");
      localStorage.removeItem("firstName");
      localStorage.removeItem("middleName");
      localStorage.removeItem("surname");
      localStorage.removeItem("DateOfBirth");
      window.location.href = "/buyonline";
    }, 7200000);
  }, [isMobileDevice]);

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Student Contact Information
        </h>
        <br />
        <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
          Please complete the details below, this information is a requirement
          to order a Smartrider
        </p>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              // backgroundColor="red"
              width="100%"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="ParentGuardianName"
                  name="ParentGuardianName"
                  label="Parent/Guardian Name"
                  value={values.ParentGuardianName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="false"
                  error={
                    touched.ParentGuardianName && errors.ParentGuardianName
                  }
                  helperText={
                    errors.ParentGuardianName &&
                    touched.ParentGuardianName &&
                    errors.ParentGuardianName
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  style={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="Phone"
                  name="Phone"
                  label="Phone"
                  value={values.Phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="false"
                  error={touched.Phone && errors.Phone}
                  helperText={errors.Phone && touched.Phone && errors.Phone}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "100%" }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} padding={{ paddingTop: "20px" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="Email"
                  name="Email"
                  label="Parent Email (Required)"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="false"
                  error={touched.Email && errors.Email}
                  helperText={errors.Email && touched.Email && errors.Email}
                  InputLabelProps={{ shrink: true }}
                  required
                  style={{ width: "100%" }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={2}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                padding={{ paddingTop: "20px" }}
              >
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleAddressSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      {/* <input
                    {...getInputProps({ placeholder: "Enter Address" })}
                    style={{ width: "99.5%", height: "50px" }}
                  /> */}
                      <TextField
                        name="address"
                        variant="outlined"
                        fullWidth
                        label="Address Lookup"
                        value={{ address }}
                        {...getInputProps({ placeholder: "Enter Address" })}
                        required
                      ></TextField>
                      <div>
                        {loading ? <div>...loading</div> : null}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#0099d8"
                              : "#266798",
                            width: "99%",
                            fontSize: isMobileDevice ? "24px" : "16px",
                          };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <Grid item xs={12} sm={12} md={2}></Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                padding={{ paddingTop: "20px" }}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                padding={{ paddingTop: "20px" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  padding={{ paddingTop: "20px" }}
                >
                  <TextField
                    id="Street"
                    name="Street"
                    label="Address (Required)"
                    value={values.Street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="false"
                    error={touched.Street && errors.Street}
                    helperText={
                      errors.Street && touched.Street && errors.Street
                    }
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { textTransform: "capitalize" } }}
                    required
                    style={{ width: "100%" }}
                  ></TextField>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  padding={{ paddingTop: "20px" }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      padding={{ paddingTop: "20px" }}
                    >
                      <TextField
                        id="Suburb"
                        name="Suburb"
                        label="Suburb (Required)"
                        value={values.Suburb}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="false"
                        error={touched.Suburb && errors.Suburb}
                        helperText={
                          errors.Suburb && touched.Suburb && errors.Suburb
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ style: { textTransform: "capitalize" } }}
                        required
                        style={{ width: "100%" }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="State"
                        name="State"
                        label="State"
                        value={values.State}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="false"
                        error={touched.State && errors.State}
                        helperText={
                          errors.State && touched.State && errors.State
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        required
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        id="Postcode"
                        name="Postcode"
                        label="Postcode (Required)"
                        value={values.Postcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="false"
                        error={touched.Postcode && errors.Postcode}
                        helperText={
                          errors.Postcode && touched.Postcode && errors.Postcode
                        }
                        InputLabelProps={{ shrink: true }}
                        required
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={2}></Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} padding={{ paddingTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={1}></Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Button
                    style={
                      window.innerWidth > 775
                        ? {
                            backgroundColor: "#D3212D",
                            color: "#ffffff",
                            marginLeft: "30%",
                            fontSize: 20,
                            width: "50%",
                          }
                        : {
                            backgroundColor: "#D3212D",
                            color: "#ffffff",
                            marginLeft: "30%",
                            fontSize: "110%",
                          }
                    }
                    onClick={back}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Button
                    style={
                      window.innerWidth > 775
                        ? {
                            backgroundColor: "green",
                            color: "#ffffff",
                            marginLeft: "2%",
                            fontSize: 20,
                            width: "50%",
                          }
                        : {
                            backgroundColor: "green",
                            color: "#ffffff",
                            marginLeft: "2%",
                            fontSize: "110%",
                          }
                    }
                    onClick={next}
                  >
                    Continue
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={1}></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "18%" }}></Grid>
        </Container>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const listStyle = {
  fontSize: 18,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "30%",
  paddingRight: "30%",
};

const listStyle1 = {
  fontSize: "140%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "15%",
  paddingRight: "15%",
};

const styleCheckBox = {
  fontSize: 16,
  color: "#ffffff",
  textAlign: "left",
};

export default StudentContactInformation;
