import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Button,
  MenuItem,
  Select,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { DatabaseContext } from "../../contexts/DatabaseContext";
import { useFormik, yupToFormErrors } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function SelectSchool() {
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [SiteNames, setSitesNames] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [schoolDeliveryNotice, setSchoolDeliveryNotice] = useState("");
  const [deliveryAgree, setDeliveryAgree] = useState(false);
  const [showReOrder, setShowReOrder] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const back = async () => {
    navigate("/termsandconditions", { replace: true });
  };

  const next = async () => {
    if (values.SiteName) {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/search/?filterInput=${values.SiteName}`
      );

      var schoolDetails = res.data.data[0];
      var SiteCode = schoolDetails.siteCode;

      var isWondeSchool = schoolDetails.wondeAllowed > 0 ? true : false;

      localStorage.setItem("siteName", values.SiteName);
      localStorage.setItem("siteCode", SiteCode);
      localStorage.setItem("siteOrderTypeID", values.OnlineOrderTypeID);

      if (schoolDetails.onlineOrderTypeID === 0 && !isWondeSchool) {
        alert(
          `No Online Orders\n\n${schoolDetails.siteName} doesn't allow Smartrider cards to be ordered online. \n\nYou will have to contact the school to order a Smartrider or you can order a non school Smartrider Card by printing and completing the Application (Manual Form)`
        );
      } else {
        if (values.ValidationType == 0) {
          navigate("/schoolstudentlookup", { replace: true });
        } else navigate("/studentlookup", { replace: true });
        //   //DET
      }
    }
  };

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilterInput(value);
  };

  const searchData = async () => {
    const res = await Axios.get(
      `${IDCaptureAPI}/api/smartrider/search/?filterInput=${filterInput}`
    ).catch((err) => {
      enqueueSnackbar(err.message, { variant: "error" });
      throw err.message;
    });
    setShowReOrder(false);

    setDeliveryAgree(res.data.data[0].postTo == 0 ? true : false);

    setSitesNames(res.data.data);
    if (res.data.data.length > 0) {
      setValues({
        SiteName: res.data.data[0].siteName,
        SiteID: res.data.data[0].siteID,
        SiteCode: res.data.data[0].siteCode,
        OnlineOrderTypeID: res.data.data[0].onlineOrderTypeID,
        PostTo: res.data.data[0].postTo,
        ValidationType: res.data.data[0].validationType,
      });
      values.SiteName = res.data.data[0].siteName;
      values.SiteCode = res.data.data[0].siteCode;
      values.SiteID = res.data.data[0].siteID;
      values.OnlineOrderTypeID = res.data.data[0].onlineOrderTypeID;
      values.PostTo = res.data.data[0].postTo;
      values.ValidationType = res.data.data[0].validationType;
    }
    handleSchoolChange();
  };

  const handleChange = async (e) => {
    const res = await Axios.get(
      `${IDCaptureAPI}/api/smartrider/setsearch/?filterInput=${e.target.value}`
    );
    setShowReOrder(false);

    if (res.data.data.length > 0) {
      setValues({
        SiteName: res.data.data[0].siteName,
        SiteID: res.data.data[0].siteID,
        SiteCode: res.data.data[0].siteCode,
        OnlineOrderTypeID: res.data.data[0].onlineOrderTypeID,
        PostTo: res.data.data[0].postTo,
        ValidationType: res.data.data[0].validationType,
      });
      values.SiteName = res.data.data[0].siteName;
      values.SiteCode = res.data.data[0].siteCode;
      values.SiteID = res.data.data[0].siteID;
      values.OnlineOrderTypeID = res.data.data[0].onlineOrderTypeID;
      values.PostTo = res.data.data[0].postTo;
      values.ValidationType = res.data.data[0].validationType;
    }
    handleSchoolChange();
  };

  const handleCheckChange = () => {
    setDeliveryAgree(!deliveryAgree);
  };

  const handleSchoolChange = async () => {
    const res = await Axios.get(
      `${IDCaptureAPI}/api/smartrider/search/?filterInput=${values.SiteName}`
    );
    var schoolDetails = res.data.data[0];
    var isWondeSchool = schoolDetails.wondeAllowed > 0 ? true : false;

    switch (values.PostTo) {
      case -1:
        //Delivery to Patorns
        setSchoolDeliveryNotice(`${schoolDetails.siteName} doesn't allow Smartrider cards to be ordered online. You will have to contact the
          school to order a Smartrider or you can order a non school Smartrider Card by printing and completing the Application (Manual Form)`);
        setShowReOrder(true);
        break;
      case 1:
        //Delivery to School
        setSchoolDeliveryNotice(
          `All orders will be sent to ${values.SiteName} for collection`
        );
        setDeliveryAgree(false);
        break;

      default:
        setSchoolDeliveryNotice(
          `All orders will be sent to the address that you enter during the ordering process`
        );
        setDeliveryAgree(false);
        break;
    }
  };

  const MFD = async () => {
    window.open(
      "https://www.transperth.wa.gov.au/Portals/0/Asset/Documents/SmartRider/Student_SmartRider_Form.pdf"
    );
  };

  const Reorder = async () => {
    if (values.SiteName) {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/search/?filterInput=${values.SiteName}`
      );

      var schoolDetails = res.data.data[0];
      var SiteCode = schoolDetails.siteCode;

      localStorage.setItem("siteName", values.SiteName);
      localStorage.setItem("siteCode", SiteCode);
      localStorage.setItem("siteOrderTypeID", values.OnlineOrderTypeID);

      // if (schoolDetails.onlineOrderTypeID === 0 && !isWondeSchool) {
      //   alert(
      //     `No Online Orders\n\n${schoolDetails.siteName} doesn't allow Smartrider cards to be ordered online. \n\nYou will have to contact the school to order a Smartrider or you can order a non school Smartrider Card by printing and completing the Application (Manual Form)`
      //   );
      // } else {
      window.location.href = "/reorder";
      // }
    }
  };

  const { handleBlur, values, touched, errors, setValues } = useFormik({
    initialValues: {
      SiteName: "",
      SiteID: 0,
      SiteCode: "",
      OnlineOrderTypeID: 0,
      DeliveryAgree: false,
      PostTo: 0,
      ValidationType: 1,
    },
    validationSchema: Yup.object({
      SiteID: Yup.number().integer(),
      SiteName: Yup.string().required(),
      SiteCode: Yup.string().required(),
      OnlineOrderTypeID: Yup.number().integer(),
      DeliveryAgree: Yup.boolean(),
      PostTo: Yup.number().integer(),
      ValidationType: Yup.number().integer(),
    }),
  });

  useEffect(() => {
    // (async () => {
    //   const res = await Axios.get(`${IDCaptureAPI}/smartrider/getSites/`);
    //   console.log(res);
    //   setSitesNames(res.data.data);
    // })();
    setTimeout(() => {
      window.location.href = "/buyonline";
    }, 7200000);
  }, [IDCaptureAPI]);

  return (
    <div className="App">
      <div className="App-content">
        <form>
          <Grid>
            <Grid item xs={12} sm={12} md={12}>
              <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
                Select Your School
              </h>
            </Grid>
            <Grid item xs={12} sm={12} md={12}></Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p style={window.innerWidth > 775 ? styleObj : styleObj1}>
                <span>
                  Search your school using the search facility below, you only
                  need to enter part of your school's name and then press the
                  "Search" button to find your school. All schools that match
                  your search criteria will listed below please select your
                  school from this list.{" "}
                </span>
                <br />
                <br />
                It is a requirement to order a smartrider that you nominate your
                school, this will be used as part of the process to verify you
                as a student.
                <br />
                <br />
                If your school is not listed below then it may be because your
                school doesn't allow online orders or the school is not
                registered to order smartrider cards.
                <br />
                <br />
                You can download and print an application form by clicking the
                Manual Form button below.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={12}></Grid>

            <Grid container spacing={0}>
              <Grid item xs={12} sm={6} md={3}></Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl>
                  <h style={schoolHeader}>
                    Enter Part of School Name to Search
                  </h>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="searchValue"
                  label="School Name (Required)"
                  helperText="Please enter all or part of the school name and click on search"
                  value={filterInput}
                  onChange={handleFilterChange}
                  inputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth={true}
                  required
                  onKeyUp={async (event) => {
                    if (event.key === "Enter") {
                      await searchData();
                    }
                    if (event.key === "Escape") {
                      setFilterInput("");
                      await searchData(true);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <div style={{ marginTop: "-19px" }}>
                        <Button
                          color="secondary"
                          startIcon={<SearchIcon />}
                          onClick={searchData}
                          style={{
                            ...(filterInput != ""
                              ? searchButton
                              : searchButtonDisable),
                            width: "150px",
                          }}
                          disabled={filterInput == ""}
                        >
                          Search
                        </Button>
                      </div>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2}></Grid>

              {window.innerWidth > 775 ? (
                <>
                  <Grid item xs={6} sm={3} md={3}></Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl fullWidth style={{ marginLeft: "10%" }}>
                      <InputLabel
                        style={{
                          color: "white",
                          marginLeft: "-9%",
                          fontSize: "25px",
                        }}
                        id="SiteName-Label"
                      >
                        {filterInput != "" ? "School" : `No School Selected`}
                      </InputLabel>
                      <Select
                        id="SiteName"
                        name="SiteName"
                        label="SiteName-Label"
                        labelId="SiteName-Label"
                        value={values.SiteName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        //defaultValue={SiteNames.length > 0 ? SiteNames[1].siteName : 0}
                        error={touched.SiteName && errors.SiteName}
                        helperText={
                          errors.SiteName && touched.SiteName && errors.SiteName
                        }
                        inputProps={{ shrink: values.SiteName }}
                        style={{
                          color: "white",
                          marginLeft: "-10%",
                          marginTop: "1%",
                          width: "117%",
                        }}
                      >
                        {SiteNames.length > 0 &&
                          SiteNames.map((sn) => (
                            <MenuItem
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                              value={sn.siteName}
                              key={sn.siteCode}
                            >
                              {sn.siteName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={3} md={3}></Grid>
                </>
              ) : (
                <Grid item xs={10} sm={6} md={2} style={{ marginLeft: "13%" }}>
                  <FormControl>
                    <InputLabel id="SiteName-Label">School</InputLabel>
                    <Select
                      id="SiteName"
                      name="SiteName"
                      label="SiteName-Label"
                      labelId="SiteName-Label"
                      value={values.SiteName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      //defaultValue={SiteNames.length > 0 ? SiteNames[1].siteName : 0}
                      error={touched.SiteName && errors.SiteName}
                      helperText={
                        errors.SiteName && touched.SiteName && errors.SiteName
                      }
                      ButtonLabelProps={{ shrink: values.SiteName }}
                      style={{
                        color: "white",
                        marginTop: "1%",
                      }}
                    >
                      {SiteNames.length > 0 &&
                        SiteNames.map((sn) => (
                          <MenuItem
                            style={{ backgroundColor: "white", color: "black" }}
                            value={sn.siteName}
                            key={sn.siteCode}
                          >
                            {sn.siteName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3}></Grid>
              <>
                {schoolDeliveryNotice != "" ? (
                  <>
                    <Grid item xs={12} sm={6} md={3}></Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      {values.OnlineOrderTypeID != 0 ? (
                        <FormControlLabel
                          style={
                            window.innerWidth > 775
                              ? { marginLeft: "50%" }
                              : { marginLeft: "20%" }
                          }
                          label={
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              style={checkboxLabelStyle}
                            >
                              {schoolDeliveryNotice}
                            </Typography>
                          }
                          control={
                            <Checkbox
                              style={styleCheckBox}
                              name="DeliveryAgree"
                              check={deliveryAgree}
                              checked={deliveryAgree === true}
                              onChange={handleCheckChange}
                            />
                          }
                        />
                      ) : (
                        <p
                          style={
                            window.innerWidth > 775 ? styleObjcom : styleObjcom1
                          }
                        >
                          {schoolDeliveryNotice}
                        </p>
                      )}

                      {/* <h style={styleObj}>{schoolDeliveryNotice}</h> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}></Grid>
                  </>
                ) : (
                  <br />
                )}
              </>

              <Grid item xs={12} sm={12} md={12}></Grid>
              <Grid item xs={4} sm={4} md={3}></Grid>
              <br />

              <Grid item xs={12} sm={12} md={6}>
                <Button
                  style={
                    window.innerWidth > 775
                      ? {
                          backgroundColor: "#D3212D",
                          color: "#ffffff",
                          marginLeft: "5%",
                          fontSize: 20,
                          width: "25%",
                        }
                      : {
                          backgroundColor: "#D3212D",
                          color: "#ffffff",
                          marginLeft: "8%",
                          fontSize: "110%",
                        }
                  }
                  onClick={back}
                >
                  Back
                </Button>
                <Button
                  style={
                    window.innerWidth > 775 ? continueButton : continueButton1
                  }
                  onClick={next}
                  disabled={!deliveryAgree}
                >
                  Continue
                </Button>
                <Tooltip title="Opens to external Website">
                  <Button
                    style={
                      window.innerWidth > 775
                        ? {
                            backgroundColor: "#262846",
                            color: "#ffffff",
                            marginLeft: "2%",
                            fontSize: 20,
                            width: "25%",
                          }
                        : {
                            backgroundColor: "#262846",
                            color: "#ffffff",
                            marginLeft: "2%",
                            fontSize: "110%",
                          }
                    }
                    onClick={MFD}
                  >
                    Manual Form
                  </Button>
                </Tooltip>

                {/* <>
                  {showReOrder == true ? (
                    <Button
                      style={
                        window.innerWidth > 775
                          ? {
                              backgroundColor: '#E9E919',
                              color: '#000000',
                              marginLeft: '2%',
                              fontSize: 20,
                              width: '15%',
                            }
                          : {
                              backgroundColor: '#E9E919',
                              color: '#000000',
                              marginLeft: '2%',
                              fontSize: '110%',
                            }
                      }
                      onClick={Reorder}
                    >
                      Re-Order
                    </Button>
                  ) : (
                    <></>
                  )}
                </> */}
              </Grid>
              <Grid item xs={4} sm={4} md={3}></Grid>
            </Grid>
            <Grid style={{ marginTop: "2%" }}></Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const schoolHeader = {
  fontSize: 20,
  color: "#FFFFFF",
  alignItems: "left",
  justifyContent: "left",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleObj = {
  fontSize: 20,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "25%",
  paddingRight: "25%",
};

const styleObj1 = {
  fontSize: "140%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const styleObjcom = {
  fontSize: 16,
  color: "yellow",
  textAlign: "left",
  paddingLeft: "38%",
  //paddingRight: ,
};

const styleObjcom1 = {
  fontSize: "110%",
  color: "yellow",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const styleCheckBox = {
  fontSize: 16,
  color: "#ffffff",
  textAlign: "left",
};

const checkboxLabelStyle = {
  textAlign: "left",
  width: "100%",
  color: "#ffffff",
};

const continueButton = {
  backgroundColor: "green",
  marginLeft: "2%",
  fontSize: 20,
  width: "25%",
};

const continueButton1 = {
  backgroundColor: "green",
  marginLeft: "2%",
  fontSize: "110%",
};

const continueButtonDisable = {
  backgroundColor: "#979797",
  color: "#C5C5C5",
  marginLeft: "2%",
  fontSize: 20,
  width: "18%",
};

const searchButton = {
  marginTop: "18px",
  marginLeft: "5px",
  backgroundColor: "black",
  color: "yellow",
};

const searchValueText = {
  color: "white",
  bordercolor: "white",
  backgroundColor: "white",
};

const searchValue = {
  color: "white",
  bordercolor: "white",
  backgroundColor: "white",
};

const searchButtonDisable = {
  backgroundColor: "#979797",
  color: "#C5C5C5",
  marginTop: "18px",
  marginLeft: "5px",
};

export default SelectSchool;
