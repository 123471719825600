import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { DatabaseContext } from "../../contexts/DatabaseContext";
import Axios from "axios";
import { makeStyles } from "@mui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 120,
  },
});

function Payment() {
  const [Price, setItemPrice] = useState([]);
  const [BasePrice, setBasePrice] = useState();
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [cart, setCart] = useState([]);
  const [invoiceTotal, setInvoiceTotal] = useState();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  //const [deactivateModal, setDeactivateModal] = useState(false);
  const [LoadMessage, setLoadMessage] = useState(false);

  function loadCartArray() {
    var totalCost = 5;
    var i = 1;
    setCart([]);
    cart.splice(0);
    cart.push({
      id: i.toString(),
      itemCode: "SR",
      itemName: "Student Smartrider",
      itemOption: `(Cost is not a travel credit)`,
      itemCost: "5",
    });
    i++;

    var savedCartItems = JSON.parse(localStorage.getItem("cartItems"));

    if (savedCartItems) {
      savedCartItems.forEach((cartItem) => {
        totalCost = totalCost + Number(cartItem.itemCost);
        cart.push({
          id: i.toString(),
          itemCode: cartItem.itemID,
          itemName: cartItem.itemName,
          itemOption: cartItem.itemOption,
          itemCost: cartItem.itemCost,
        });
        i++;
      });
    }
    setInvoiceTotal(totalCost.toString());
    setCart(cart);
  }

  useEffect(() => {
    loadCartArray();
    (async () => {
      var itemCode = "SR";
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/getPrice/?itemCode=${itemCode}`
      );
      setItemPrice(res.data.data[0][0].ItemPrice);
      setBasePrice(res.data.data[0][0].BasePrice);
    })();
    setTimeout(() => {
      localStorage.removeItem("siteName");
      localStorage.removeItem("siteCode");
      localStorage.removeItem("siteOrderTypeID");
      localStorage.removeItem("firstName");
      localStorage.removeItem("middleName");
      localStorage.removeItem("surname");
      localStorage.removeItem("DateOfBirth");
      localStorage.removeItem("phone");
      localStorage.removeItem("email");
      localStorage.removeItem("parent");
      localStorage.removeItem("ResStreet");
      localStorage.removeItem("ResSuburb");
      localStorage.removeItem("ResState");
      localStorage.removeItem("ResPostcode");
      localStorage.removeItem("street");
      localStorage.removeItem("suburb");
      localStorage.removeItem("state");
      localStorage.removeItem("postcode");
      localStorage.removeItem("cartItems");
      localStorage.removeItem("ExternalID");
      window.location.href = "/buyonline";
    }, 7200000);
  }, [IDCaptureAPI]);

  const back = async () => {
    localStorage.removeItem("firstName");
    localStorage.removeItem("middleName");
    localStorage.removeItem("surname");
    localStorage.removeItem("DateOfBirth");
    localStorage.removeItem("siteName");
    localStorage.removeItem("phone");
    localStorage.removeItem("email");
    localStorage.removeItem("parent");
    localStorage.removeItem("ResStreet");
    localStorage.removeItem("ResSuburb");
    localStorage.removeItem("ResState");
    localStorage.removeItem("ResPostcode");
    localStorage.removeItem("street");
    localStorage.removeItem("suburb");
    localStorage.removeItem("state");
    localStorage.removeItem("postcode");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("ExternalID");
    localStorage.removeItem("YearGroup");
    // Remove Parent Order
    var ac = localStorage.getItem("AccessCode");

    if (ac != undefined) {
      try {
        const AO = await Axios.delete(
          `${IDCaptureAPI}/api/parentOrders/?accesscode=${ac}`
        );
      } catch (error) {
        console.log(error);
      }
    }
    navigate("/buyonline", { replace: true });
  };

  const params = {
    "client-id":
      "AbhUuOd5hJdLb4SFlK25pI0DcmDSQa7QCtqAHc-uPyq-wqLJ69j2030fCY454-qmj1TJl9rrOcgZxc-T",
    currency: "AUD",
  };

  const payEway = async () => {
    setLoadMessage(true);
    var siteName = localStorage.getItem("siteName");
    if (siteName) {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/search/?filterInput=${siteName}`
      );
      var schoolDetails = res.data.data[0];
      var isWondeSchool = schoolDetails.wondeAllowed > 0 ? true : false;
    }

    const PatronOrder = [];

    console.log(`${localStorage.getItem("street")}`);

    PatronOrder.push({
      ParentOrderID: 0,
      PatronID: 0,
      OrderID: 0,
      TransactionID: "",
      ExternalID: `${localStorage.getItem("ExternalID")}`,
      FirstName: `${localStorage.getItem("firstName")}`,
      Lastname: `${localStorage.getItem("surname")}`,
      DOB: `${localStorage.getItem("DateOfBirth")}`,
      YearGroup: `${localStorage.getItem("YearGroup")}`,
      SiteCode: `${localStorage.getItem("siteCode")}`,
      ParentName: `${localStorage.getItem("parent")}`,
      ParentEmail: `${localStorage.getItem("email")}`,
      ParentPhone: `${localStorage.getItem("phone")}`,
      Address: `${localStorage.getItem("ResStreet")}`,
      Suburb: `${localStorage.getItem("ResSuburb")}`,
      State: `${localStorage.getItem("ResState")}`,
      Postcode: `${localStorage.getItem("ResPostcode")}`,
      DeliveryAddress: `${localStorage.getItem("street")}`
        ? `${localStorage.getItem("street")}`
        : `${localStorage.getItem("ResStreet")}`,
      DeliverySuburb: `${localStorage.getItem("suburb")}`
        ? `${localStorage.getItem("suburb")}`
        : `${localStorage.getItem("ResSuburb")}`,
      DeliveryState: `${localStorage.getItem("state")}`
        ? `${localStorage.getItem("state")}`
        : `${localStorage.getItem("ResState")}`,
      DeliveryPostcode: `${localStorage.getItem("postcode")}`
        ? `${localStorage.getItem("postcode")}`
        : `${localStorage.getItem("ResPostcode")}`,
      DelToPatronAddress: 1,
      Accessories: cart,
      AccessCode: "",
    });

    try {
      const res = await Axios.post(
        `${IDCaptureAPI}/api/parentOrders/paymentPage`,
        PatronOrder
      );

      if (res.data.data) {
        localStorage.setItem("AccessCode", res.data.data.AccessCode);
        PatronOrder[0].AccessCode = res.data.data.AccessCode;

        const AO = await Axios.post(
          `${IDCaptureAPI}/api/parentOrders/?isWondeOrder=${isWondeSchool}`,
          PatronOrder
        );
        if (AO.data.success == true) {
          window.location.href = `${res.data.data.PaymentURL}`;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createOrder = (data, actions) => {
    var purchasedItems = [];
    var iTotal = 0;
    var i = 0;

    if (cart) {
      cart.forEach((cartItem) => {
        i++;
        iTotal = iTotal + Number(cartItem.itemCost);
        purchasedItems.push({
          reference_id: i.toString(),
          name: cartItem.itemOption
            ? cartItem.itemCode +
              " " +
              cartItem.itemName +
              " (" +
              cartItem.itemOption +
              ")"
            : cartItem.itemCode + " " + cartItem.itemName,
          unit_amount: { currency_code: "AUD", value: cartItem.itemCost },
          quantity: "1",
        });
      });
    }

    return actions.order.create({
      purchase_units: [
        {
          reference_id: "monitorwa_smartrider_portal_order",
          description: `Smartrider Order for ${localStorage.getItem(
            "firstName"
          )} ${localStorage.getItem("surname")} (${localStorage.getItem(
            "siteName"
          )})`,
          amount: {
            currency_code: "AUD",
            total: iTotal.toString(),
            value: iTotal.toString(),
            breakdown: {
              item_total: { currency_code: "AUD", value: iTotal.toString() },
            },
          },
          items: purchasedItems,
        },
      ],
    });
  };
  const onApprove = async (data, actions) => {
    const order = await actions.order.capture();
    window.alert("Your Payment Has Been Successful");
    const PatronOrder = [];
    PatronOrder.push(
      JSON.stringify({
        ParentOrderID: 0,
        PatronID: 0,
        OrderID: 0,
        TransactionID: order.purchase_units[0].payments.captures[0].id,
        ExternalID: `${localStorage.getItem("ExternalID")}`,
        FirstName: `${localStorage.getItem("firstName")}`,
        Lastname: `${localStorage.getItem("surname")}`,
        DOB: `${localStorage.getItem("DateOfBirth")}`,
        SiteCode: `${localStorage.getItem("siteCode")}`,
        ParentName: `${localStorage.getItem("parent")}`,
        ParentEmail: `${localStorage.getItem("email")}`,
        ParentPhone: `${localStorage.getItem("phone")}`,
        Address: `${localStorage.getItem("ResStreet")}`,
        Suburb: `${localStorage.getItem("ResSuburb")}`,
        State: `${localStorage.getItem("ResState")}`,
        Postcode: `${localStorage.getItem("ResPostcode")}`,
        DeliveryAddress: `${localStorage.getItem("street")}`,
        DeliverySuburb: `${localStorage.getItem("suburb")}`,
        DeliveryState: `${localStorage.getItem("state")}`,
        DeliveryPostcode: `${localStorage.getItem("postcode")}`,
        DelToPatronAddress: 1,
        Accessories: cart,
      })
    );

    var a = [];

    a.push(PatronOrder);
    var siteName = localStorage.getItem("siteName");

    if (siteName) {
      const res = await Axios.get(
        `${IDCaptureAPI}/api/smartrider/search/?filterInput=${siteName}`
      );
      var schoolDetails = res.data.data[0];

      var isWondeSchool = schoolDetails.wondeAllowed > 0 ? true : false;
    }

    try {
      const res = await Axios.post(
        `${IDCaptureAPI}/api/parentOrders/?isWondeOrder=${isWondeSchool}`,
        PatronOrder
      );
    } catch (err) {}

    localStorage.removeItem("firstName");
    localStorage.removeItem("middleName");
    localStorage.removeItem("surname");
    localStorage.removeItem("DateOfBirth");
    localStorage.removeItem("siteName");
    localStorage.removeItem("phone");
    localStorage.removeItem("email");
    localStorage.removeItem("parent");
    localStorage.removeItem("ResStreet");
    localStorage.removeItem("ResSuburb");
    localStorage.removeItem("ResState");
    localStorage.removeItem("ResPostcode");
    localStorage.removeItem("street");
    localStorage.removeItem("suburb");
    localStorage.removeItem("state");
    localStorage.removeItem("postcode");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("ExternalID");

    navigate("/purchasecomplete", { replace: true });
  };

  const handleDeleteAction = (row) => {
    if (row <= cart.length) {
      enqueueSnackbar(
        `${cart[row - 1].itemName} has been removed from your order`,
        {
          variant: "success",
        }
      );
      cart.splice(row - 1, 1);
      cart.splice(0, 1); //remove smartrider default order, will be added when cart is rebuilt
      localStorage.removeItem("cartItems");
      localStorage.setItem("cartItems", JSON.stringify(cart));
      loadCartArray();
    }
  };

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Smartrider Payment Summary
        </h>
        <br />
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}></Grid>
          <Grid item xs={12} sm={12} md={2}></Grid>

          <Grid item xs={12} sm={12} md={8}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell style={highlight}>Item ID</TableCell>
                    <TableCell style={highlight}>Item Code</TableCell>
                    <TableCell style={highlight}>Item Name</TableCell>
                    <TableCell style={highlight}>Item Option</TableCell>
                    <TableCell align="right" style={highlight}>
                      Cost ($)
                    </TableCell>
                    <TableCell style={highlight}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.map((row, i) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.itemCode}
                      </TableCell>
                      <TableCell> {row.itemName}</TableCell>
                      <TableCell>{row.itemOption}</TableCell>
                      <TableCell align="right">{row.itemCost}</TableCell>
                      {row.itemCode !== "SR" ? (
                        <TableCell>
                          <Tooltip title="Delete">
                            <IconButton>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                onClick={() => handleDeleteAction(row.id)}
                              ></FontAwesomeIcon>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell></TableCell>
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="right" style={highlight}>
                      Total
                    </TableCell>
                    <TableCell align="right" style={highlight}>
                      {invoiceTotal}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={2}></Grid>
        </Grid>
        {window.innerWidth < 775 ? (
          <p
            style={{ fontSize: "120%", marginLeft: "10%", marginRight: "10%" }}
          >
            Please slide the table and press delete icon to remove an item from
            cart{" "}
          </p>
        ) : (
          <></>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}></Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            <p
              style={
                window.innerWidth > 775
                  ? { fontSize: 20, marginLeft: "28%", marginRight: "28%" }
                  : { fontSize: "120%", marginLeft: "10%", marginRight: "10%" }
              }
            >
              <h style={{ color: "#FFA500" }}>Please Note:</h> You will be
              transferred to the payment gateway{" "}
              <b style={{ color: "#FFA500" }}>(Eway)</b> to complete this
              transaction, you will receive a transaction email from{" "}
              <b style={{ color: "#FFA500" }}>Eway</b> upon completion of your
              payment.
            </p>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              style={
                window.innerWidth > 775
                  ? {
                      backgroundColor: "#D3212D",
                      color: "#ffffff",
                      fontSize: 20,
                      marginLeft: "25%",
                    }
                  : {
                      backgroundColor: "#D3212D",
                      color: "#ffffff",
                      fontSize: "110%",
                      marginLeft: "15%",
                    }
              }
              onClick={back}
            >
              Cancel Order
            </Button>

            <Button
              style={
                window.innerWidth > 775
                  ? {
                      backgroundColor: "green",
                      color: "#ffffff",
                      fontSize: 20,
                      width: "250px",
                      marginLeft: "25%",
                      marginRight: "25%",
                    }
                  : {
                      backgroundColor: "green",
                      color: "#ffffff",
                      fontSize: "110%",
                      marginLeft: "15%",
                    }
              }
              onClick={payEway}
            >
              Pay Now
            </Button>
          </Grid>
          {/* <PayPalScriptProvider options={params}>
              <PayPalButtons
                style={{ layout: 'horizontal' }}
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
              />
            </PayPalScriptProvider> */}
        </Grid>
        <Grid style={{ marginTop: "8%" }}></Grid>

        {/* <Dialog
          open={deactivateModal}
          onClose={() => setDeactivateModal(false)}
        >
          <DialogContent
            style={{
              color: 'blue',
              backgroundColor: 'white',
              fontSize: '110%',
              fontWeight: 'bold',
            }}
          >
            Please Note:<br></br>
            <br></br>Failure to click the "Finalise Transaction Button" at the
            completion of the payment section will result in you being charged
            for your order but your order will not be placed.
          </DialogContent>
          <DialogActions style={{ color: 'blue', backgroundColor: 'white' }}>
            <Button
              style={{ color: 'white', backgroundColor: 'green' }}
              onClick={payEway}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog> */}

        <Dialog open={LoadMessage}>
          <DialogContent
            style={{
              color: "blue",
              backgroundColor: "white",
              fontSize: "110%",
              fontWeight: "bold",
            }}
          >
            Please wait Eway Payment Page is loading....
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const highlight = {
  fontSize: 18,
  color: "#FFFFFF",
  fontWeight: "bold",
};

export default Payment;
